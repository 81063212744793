import Fetch from "./Fetch";

async function createNewBooking(bookingObject) {
  const response = await Fetch.post("/booking/services", bookingObject);
  return response;
}

async function createDraftBooking(bookingObject) {
  const response = await Fetch.post("/booking/services/save", bookingObject);
  return response;
}
async function cancelBooking(bookingObject) {
  const response = await Fetch.remove("/booking/services", bookingObject);
  return response;
}

async function approveBooking(bookingObject) {
  const response = await Fetch.post("/booking/services/approve", bookingObject);
  return response;
}
async function rejectBooking(bookingObject) {
  const response = await Fetch.post("/booking/services/reject", bookingObject);
  return response;
}
async function reScheduleBooking(bookingObject) {
  const response = await Fetch.put("/booking/services", bookingObject);
  return response;
}
async function updateComments(bookingObject) {
  const response = await Fetch.post("/booking/services/comments", bookingObject);
  return response;
}

async function updateDocuments(bookingObject) {
  const response = await Fetch.put("/booking/services/documents", bookingObject);
  return response;
}

async function getAllBookings(userEmail) {
  const response = await Fetch.get(`/booking/services/all?name=${userEmail}`);
  return response;
}
async function getBookingById(bookingId) {
  const response = await Fetch.get(`/booking/services?id=${bookingId}`);
  return response;
}
async function getBookingStatusCount(){
  const response = await Fetch.get(`/booking/services/status/count`);
  return response;
}

async function submitFeedback(bookingObject){
  const response = await Fetch.put(`/booking/services/feedback`,bookingObject);
  return response;
}

const bookingRepository = {
  createNewBooking,
  getAllBookings,
  getBookingById,
  reScheduleBooking,
  cancelBooking,
  createDraftBooking,
  getBookingStatusCount,
  approveBooking,
  rejectBooking,
  updateComments,
  updateDocuments,
  submitFeedback
};

export default bookingRepository;
