export const convertCreateBookingToEditBookingPayload = (createBooking) => {
  const unWantedFields = [
    "assignee",
    "candidateWhatsApp",
    "linkedInProfile",
    "submissionDate",
    "workFlow"
  ];
  let { startTime, timeZone, endTime } = createBooking?.meetingInfo;
  let editBooking = { ...createBooking };
  unWantedFields.forEach((i) => {
    delete editBooking[i];
  });
  editBooking.meetingInfo = { startTime, timeZone, endTime };
  console.log(editBooking);
  return editBooking;
};
