// Fetch.js
/**
 * https://javascript.plainenglish.io/the-ultimate-javascript-fetch-api-cheatsheet-e60b98c8cdbe

 
 */

const API_HOST =
  "https://api.twirln.com";


async function request(url, params, method = "GET", resType = "json") {
  const user = JSON.parse(sessionStorage.getItem('user'))
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": true,
      // "Access-Control-Max-Age": 86400,
      ...(user?.token && { Authorization: `Bearer ${user?.token}` }),
    },
    // credentials: "include",
  };

  function objectToQueryString(obj) {
    return Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join("&");
  }

  if (params) {
    if (method === "GET") {
      // eslint-disable-next-line no-param-reassign
      url += `?${objectToQueryString(params)}`;
    } else {
      options.body = JSON.stringify(params);
    }
  }

  // function generateErrorResponse(message, code) {
  //   const response = {
  //     status: "error",
  //     message,
  //   };
  //   return code ? { ...response, code } : response;
  // }

  try {
    const response = await fetch(API_HOST + url, options);
    const result = resType === "json" ? await response.json() : response;

    // if (response.status >= 400) {
    //   const msg =
    //     typeof result === "string"
    //       ? result
    //       : "The server responded with an unexpected status.";
    //   return generateErrorResponse(msg);
    // }

    // handle errors that return with a 200 status
    // if (result.message_type === "error") {
    //   const msg =
    //     response.reason ||
    //     result.reason ||
    //     "An error occurred fetching resources.";
    //   const { code } = result;

    //   return generateErrorResponse(msg, code);
    // }

    return result;
  } catch (e) {
    return e
  }
}

function get(url, params) {
  return request(url, params);
}

function post(url, params) {
  return request(url, params, "POST");
}

function put(url, params) {
  return request(url, params, "PUT");
}

function remove(url, params) {
  return request(url, params, "DELETE");
}

async function postFile(url,file) {
   const user = JSON.parse(sessionStorage.getItem("user"));
   const formData = new FormData();
   formData.append("file", file);
   const options = {
     method: "POST",
     headers: {
       "Access-Control-Allow-Origin": true,
       // "Access-Control-Max-Age": 86400,
       ...(user?.token && { Authorization: `Bearer ${user?.token}` })
     },
     body: formData,
     // credentials: "include",
   };
  const response = await fetch(API_HOST+url, options);
  const fileData = await response.json();
  return fileData
}
async function getFile(url) {
   const user = JSON.parse(sessionStorage.getItem("user"));
   const options = {
     method: "GET",
     headers: {
       ...(user?.token && { Authorization: `Bearer ${user.token}` })
     }     
   };
   const response = await fetch(API_HOST + url, options);
   return response;
}


const Fetch = {
  get,
  post,
  put,
  remove,
  postFile,
  getFile
};

export default Fetch;
